var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slab-button", class: { destructive: _vm.destructive } },
    [
      _vm._t("default", function () {
        return [_vm._v("Button")]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }