<template>
    <div
        class="slab-button"
        :class="{destructive: destructive}"
    >
    
        <slot>Button</slot>
    </div>
</template>

<script>
export default {
    name: 'SlabButton',
    props: {
        destructive: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
    .slab-button {
        background: #93BD20;
        cursor: pointer;
        box-shadow: inset 0 -2px 0 0 #779D0D;
        border-radius: 5px;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 14px;
        display: inline-block;
        padding: 18px 40px;
        margin: 10px 10px;
    }

    .destructive {
        background: #ff1744;
    }
</style>
