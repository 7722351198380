<template>
    <div>
        <context-title 
            :title="title" 
            :passedActions="[]" />

        <div class="buttons">
            <router-link :to="{name: 'store-mod-add-new', param: $route.params}">
                <slab-button>Create new mod</slab-button>
            </router-link>
            <router-link :to="{name: 'store-mod-add-database', param: $route.params}">
                <slab-button>Select mod from database</slab-button>
            </router-link>
        </div>
    </div>
</template>

<script>
import contextTitle from '../components/context_title.vue'
import slabButton from '../components/slab_button.vue'

export default {
    name: 'StoreProductAdd',
    components: {
        contextTitle,
        slabButton,
    },
    data: () => ({
        title: [
            {to: {name: 'store-mod-index'}, label: 'Mods'},
            'Add New Mod',
        ],
    }),
}
</script>

<style lang="scss">

.buttons {
    padding: 10px;
}

</style>
