var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("context-title", { attrs: { title: _vm.title, passedActions: [] } }),
      _c(
        "div",
        { staticClass: "buttons" },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: { name: "store-mod-add-new", param: _vm.$route.params },
              },
            },
            [_c("slab-button", [_vm._v("Create new mod")])],
            1
          ),
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "store-mod-add-database",
                  param: _vm.$route.params,
                },
              },
            },
            [_c("slab-button", [_vm._v("Select mod from database")])],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }